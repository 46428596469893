import { LitElement, html, css, customElement, property } from "lit-element"
import { GlobalCSS } from "../css"

// Import assets here
// import assetExample from '../../assets/images/example.svg'

@customElement("app-news")
export class News extends LitElement {
    // @property({type: String}) private myProperty: string // Observed property Example

    constructor() {
        super()
        // this.myProperty = '' // Default value
    }

    render() {
        return html`
            <h1>News</h1>
        `
    }

    static styles = [GlobalCSS, css`
        
    `]
}