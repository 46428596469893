import {LitElement, html, css, customElement, property, query} from "lit-element"
import { router } from "lit-element-router"
import { GlobalCSS } from "./css"

// Import components here
import "./components/navigation"
import "./components/footer"

// Import pages here
import "./pages/home"
import "./pages/imprint"
import "./pages/notfound"

@customElement("app-root")
export class App extends LitElement {
    @property({type: String}) private route: string

    @query("app-navigation")
    navigation;

    @query("router-slot")
    mainRouter;

    constructor() {
        super()
        this.route = "" // Default value
        router([{
            name: "home",
            pattern: "/",
        }, {
            name: "imprint",
            pattern: "imprint",
            data: { title: "Impressun" },
        }, {
            name: "privacy",
            pattern: "privacy",
            data: { title: "Datenschutzerlärung" },
        }, {
            name: "not-found",
            pattern: "*",
            data: { title: "Error 404!" },
        }], (route, params, query, data) => {
            // Scroll back to top
            window.scrollTo(0, 0)

            // Change route
            this.route = route

            // Change browser tab title
            let title = "Gebongt"
            if (data && data.title && data.title !== "") {
                title += " | " + data.title
            }
            document.title = title
        })
    }

    scrollToElement(ev) {
        const home = this.mainRouter.shadowRoot.querySelector("app-home");

        if (home) {
            home.scrollToElement(ev.detail.id);
        }
    }

    render() {
        return html`
            <app-navigation @scrollto="${ev => this.scrollToElement(ev)}" route='${this.route}'></app-navigation>
            <router-slot route='${this.route}'>
                <app-home @headerarea="${ev => this.navigation.handleHeaderAreaEvent(ev)}" slot='home'></app-home>
                <app-privacy slot='privacy'></app-privacy>
                <app-imprint slot='imprint'></app-imprint>
                <app-notfound slot='not-found'></app-notfound>
            </router-slot>
            <app-footer></app-footer>
        `
    }

    static styles = [GlobalCSS, css`
        :host {
            background-color: #eee;
        }

        img {
            width: 20rem;
        }
    `]
}