import { LitElement, html, css, customElement, property, query } from "lit-element"
import { GlobalCSS } from "../css"

// Import assets here
import assetHamburger from '../../assets/images/hamburger.svg'

@customElement("app-navigation")
export class Navigation extends LitElement {
    @property({type: String}) private route: string

    @query("nav") navContainer;
    @query("#overlay") overlay;
    @query("#nav-background")
    navBackground;

    inHeader = true;
    keepVisible = false;

    constructor() {
        super()
        this.route = "" // Default value
    }

    crossAnimation() {
        this.shadowRoot.getElementById("line-1").style.transform = "translate(-2.4px, 2.7px) rotate(45deg)";
        this.shadowRoot.getElementById("line-2").style.transform = "translate(-2.5px, -2.6px) rotate(-45deg)";
        this.shadowRoot.getElementById("line-3").style.opacity = "0";
    }

    crossAnimationOff() {
        this.shadowRoot.getElementById("line-1").style.removeProperty("transform");
        this.shadowRoot.getElementById("line-2").style.removeProperty("transform");
        this.shadowRoot.getElementById("line-3").style.removeProperty("opacity");
    }

    navState = false;

    openNav() {
        this.navState = true;
        this.navContainer.classList.add("mobile-nav-active");
        this.overlay.style.display = "block";
        document.body.classList.add("no-scroll");
        this.crossAnimation();
    }

    closeNav() {
        this.navState = false;
        this.navContainer.classList.remove("mobile-nav-active");
        this.overlay.style.display = "none";
        document.body.classList.remove("no-scroll");
        this.crossAnimationOff();
    }

    toggleNav() {
        if (this.navState) {
            this.closeNav();
        } else {
            this.openNav();
        }
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('scroll', this.handleGlobalScroll.bind(this));
    }

    /*disconnectedCallback() {
        window.removeEventListener('scroll', this.handleGlobalScroll.bind(this));
        super.disconnectedCallback();
    }*/

    lastPageOffset = 0;
    handleGlobalScroll() {
        const newPageOffset = window.pageYOffset;

        if (newPageOffset < this.lastPageOffset) {
            // scrolling up

            if (!this.inHeader) {
                this.navBackground.classList.add("active");
                this.navContainer.classList.add("nav-visible");
            }
        } else {
            // scrolling down

            if (!this.inHeader && !this.keepVisible) {
                this.navBackground.classList.remove("active");
                this.navContainer.classList.remove("nav-visible");
            }
        }

        this.lastPageOffset = newPageOffset;
    }

    handleHeaderAreaEvent(ev) {
        const inHeader = ev.detail.inHeaderArea;
        this.inHeader = inHeader;

        if (inHeader) {
            this.navContainer.classList.add("nav-visible");
            this.navBackground.classList.remove("active");
        } else {

            if (!this.keepVisible) {
                this.navContainer.classList.remove("nav-visible");
            }
        }
    }

    handleMouseLeave(ev) {
        console.log("ev");
        if (ev.target) {
            const target = ev.target;

            target.classList.add("nav-element-fading-out");

            if (target._ctTimeout) clearTimeout(target._ctTimeout);
            target._ctTimeout = setTimeout(() => {
                target.classList.remove("nav-element-fading-out");
            }, 600);
        }
    }

    handleMouseEnter(ev) {
        if (ev.target && ev.target._ctTimeout) {
            clearTimeout(ev.target._ctTimeout);
        }
    }

    keepVisibleTimeout = null;
    handleClick(id) {
        this.keepVisible = true;
        this.navBackground.classList.add("active");
        this.navContainer.classList.add("nav-visible");
        if (this.keepVisibleTimeout) clearTimeout(this.keepVisibleTimeout);
        this.keepVisibleTimeout = setTimeout(() => {
            this.keepVisible = false;
        }, 1000);

        this.dispatchEvent(new CustomEvent("scrollto", {
            detail: {
                id: id
            }
        }));

        if (this.navState) {
            this.closeNav();
        }
    }

    render() {
        return html`
            <nav class="nav-visible">
                <div id="nav-background"></div>
                <div id="mobile-nav-container">
                    <div @click="${ev => this.handleClick('concept-anchor')}" @mouseleave="${this.handleMouseLeave}" @mouseenter="${this.handleMouseEnter}" class="nav-element"><div class="nav-text">Konzept</div></div>
                    <div @click="${ev => this.handleClick('app-anchor')}" @mouseleave="${this.handleMouseLeave}" @mouseenter="${this.handleMouseEnter}" class="nav-element"><div class="nav-text">App</div></div>
                    <div @click="${ev => this.handleClick('why-anchor')}" @mouseleave="${this.handleMouseLeave}" @mouseenter="${this.handleMouseEnter}" class="nav-element"><div class="nav-text">Warum?</div></div>
                    <div @click="${ev => this.handleClick('privacy-anchor')}" @mouseleave="${this.handleMouseLeave}" @mouseenter="${this.handleMouseEnter}" class="nav-element"><div class="nav-text">Datenschutz</div></div>
                    <div @click="${ev => this.handleClick('contact-anchor')}" @mouseleave="${this.handleMouseLeave}" @mouseenter="${this.handleMouseEnter}" class="nav-element"><div class="nav-text">Kontakt</div></div>
                </div>
            </nav>

            <div @click="${ev => this.toggleNav()}" id="hamburger-container">
            <svg width="26" height="26" version="1.1" viewBox="0 0 22 22">
                <g transform="translate(-.18245 -.23568)" fill="none" stroke="#fff" stroke-width="2.1025">
                    <path id="line-1" d="m2.5635 7.2357h17.238"/>
                    <path id="line-3" d="m2.5635 11.236h17.238"/>
                    <path id="line-2" d="m2.5635 15.236h17.238"/>
                </g>
                </svg>
            </div>
            <div id="hamburger-container-bg"></div>
            <div @click="${ev => this.closeNav()}" id="overlay"></div>
        `
    }

    static styles = [GlobalCSS, css`
        
        #line-1 {
            transform-origin: center;
            transition: .4s transform ease-in-out, .4s opacity ease-in-out;
        }

        #line-2 {
            transform-origin: center;
            transition: .4s transform ease-in-out, .4s opacity ease-in-out;
        }

        #line-3 {
            transition: .4s opacity ease-in-out;
        }

        nav {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            position: fixed;
            width: 100%;
            font-size: 1.1rem;
            z-index: 10;
            height: 5.5rem;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0.2s linear, opacity 0.2s ease-in-out;
        }
        
        nav.nav-visible {
            visibility: visible;
            opacity: 1;
        }
        
        #nav-background {
            background-color: rgb(15, 124, 255);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            transition: height 0.6s cubic-bezier(.16,.78,0,1.01);
        }
        
        #nav-background.active {
            height: 100%;
        }

        #mobile-nav-container {

        }

        @media screen and (max-width: 50rem) {
            nav {
                position: fixed;
                flex-direction: column;
                justify-content: flex-start;
                margin: 0px;
                top: 0px;
                left: 100%;
                max-width: 50%;
                height: 100%;
                background-color: black;
                transform: translateX(0%);
                transition: transform 0.5s ease 0s, visibility 0.2s linear, opacity .2s ease-in-out;
                overflow: hidden;
                z-index: 10;
            }

            #mobile-nav-container {
                margin-top: 7rem;
            }

            #nav-background {
                display: none;
            }
        }

        @media screen and (max-width: 40rem) {
            nav {
                max-width: 65%;
            }
        }

        .mobile-nav-active {
            transform: translateX(-100%) !important;
            opacity: 1 !important;
            visibility: visible !important;
        }

        #overlay {
            display: none;
            top: 0;
            left: 0;
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 9;
        }

        #hamburger-container {
            display: none;
            justify-content: center;
            align-items: center;
            top: 2rem;
            right: 2rem;
            position: fixed;
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 25%;
            z-index: 11;
        }

        #hamburger-container > img:nth-child(1) {
            width: 1.9rem;
        }

        @media screen and (max-width: 50rem) {
            #hamburger-container {
                display: flex;
            }
        }

        #hamburger-container-bg {
            display: none;
            top: 2rem;
            right: 2rem;
            position: fixed;
            width: 3.2rem;
            height: 3.2rem;
            background-color: black;
            border-radius: 5%;
            z-index: -5;
        }

        @media screen and (max-width: 50rem) {
            #hamburger-container-bg {
                display: block;
            }
        }
        
        .nav-element {
            margin-left: 1.2rem;
            margin-right: 1.2rem;
            position: relative;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 600;
        }

        .nav-text {
            
        }

        @media screen and (max-width: 50rem) {
            .nav-element {
                align-items: flex-end;
                width: 100%;
                height: 3.5rem;
                margin-left: 0;
                margin-right: 0;
            }

            .nav-text {
                margin-right: 3rem;
            }
        }
        
        .nav-element::after {
            content: "";
            display: block;
            width: 0;
            height: .1rem;
            background-color: white;
            transition: width 0.2s cubic-bezier(0,0,.07,.98);
            align-self: start;
        }
        
        .nav-element-fading-out::after {
            align-self: flex-end;
            transition: width 0.6s cubic-bezier(0,1.09,.49,.93);
        }
        
        .nav-element:hover::after {
            width: 100%;
        }
    `]
}