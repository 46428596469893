import {LitElement, html, css, customElement, property, query} from "lit-element"
import { GlobalCSS } from "../css"

import assetBgLeft from '../../assets/images/bg-left.svg'
import assetBgRight from '../../assets/images/bg-right.svg'
import assetBgFade from '../../assets/images/bg-fade.svg'
import assetLogo from '../../assets/images/gebongt_logo.svg'

import assetScreen1 from '../../assets/images/screen_1.jpg'
import assetScreen2 from '../../assets/images/screen_2.jpg'
import assetScreen3 from '../../assets/images/screen_3.jpg'

import assetStep1 from '../../assets/images/step_1.svg'
import assetStep2 from '../../assets/images/step_2.svg'
import assetStep3 from '../../assets/images/step_3.svg'

import assetUserGraphic from '../../assets/images/smartphonenutzer.png'
import assetSquares from '../../assets/images/squares.svg'

@customElement("app-home")
export class Home extends LitElement {
    // @property({type: String}) private myProperty: string // Observed property Example

    @query("#navigation-marker")
    navMarker;

    inHeaderArea = true;

    constructor() {
        super()
        // this.myProperty = '' // Default value
    }

    scrollToElement(id: string) {
        //const ss = new SmoothScroll();
        //ss.animateScroll(this.shadowRoot.getElementById(id));

        this.shadowRoot.getElementById(id).scrollIntoView();
    }

    handleGlobalScroll() {
        const offset = this.navMarker.getBoundingClientRect().top;

        if ( offset < 0 && this.inHeaderArea) {
            this.inHeaderArea = false;
            const cEv = new CustomEvent("headerarea", {
                detail: {
                    inHeaderArea: this.inHeaderArea
                }
            });

            this.dispatchEvent(cEv);
        } else if (offset > 0 && !this.inHeaderArea) {
            this.inHeaderArea = true;
            const cEv = new CustomEvent("headerarea", {
                detail: {
                    inHeaderArea: this.inHeaderArea
                }
            });

            this.dispatchEvent(cEv);
        }

    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('scroll', this.handleGlobalScroll.bind(this));
    }

    disconnectedCallback() {
        window.removeEventListener('scroll', this.handleGlobalScroll.bind(this));
        super.disconnectedCallback();
    }

    async sendContactForm() {
        const form = this.shadowRoot.getElementById("contact-form") as HTMLFormElement
        const formData = new FormData(form)

        const id = formData.getAll("id")
        if (!id || id.length !== 1) {
            return
        }

        const api = {
            name: "mail",
            version: "v1",
            function: `send/external/${id[0]}`,
        }

        try {
            await fetch(`https://${api.name}-${api.version}.apis.cedgetec.com/${api.function}`, {
                method: "POST",
                headers: new Headers(),
                body: formData,
            })
            form.reset()
            alert("Das Formular wurde erfolgreich gesendet")
        } catch (err) {
            form.reset()
            alert("Das Formular konnte nicht gesendet werden")
        }
    }


    render() {
        return html`
                <img id="head-img" src="${assetSquares}">
                <div id="head-background">
                    <img id="head-icon" src="${assetLogo}">
                    <img id="head-fade" src="${assetBgFade}">
                    <div id="head-headline">
                        <div>Gebongt.</div>
                        <div>Statt Kassenbon.</div>
                    </div>
                </div>
                
                <main>
                    <div id="navigation-marker"></div>
                    <div id="first-content-block" class="content-block">
                        <div class="content-headline"><div id="concept-anchor"></div>Das Konzept</div>
                        <div class="content-sub-headline">Die Alternative zur Nutzung von Kassenbonpapier</div>
                        <div class="content-text">
        
                            <div>
                                Trotz der zunehmenden Digitalisierung ist der Papierverbrauch in den Industriestaaten noch immer enorm hoch, obwohl 
                                sich durch den Umstieg von analogen auf digitale Güter an vielen Stellen Chancen zur Reduzierung des 
                                Papierverbrauchs auftun. <br><br>
        
                                Eine Papierquelle, die jeder Kunde täglich nutzt, sind Kassenbons. Gedruckt werden sie bei jedem Kauf, 
                                auch wenn der Kunde sie nicht immer entgegen nimmt. <br><br>
                                
                                Kritisiert wurde der Umgang mit Kassenbons in jüngster Zeit vor allem, weil sie aus Thermopapier bestehen. 
                                Dieses Papier enthielt das gesundheitsschädliche Bisphenol-A, und nun nach dessen Verbot das im Prinzip 
                                genauso gesundheitsschädliche Bisphenol-S.
                            </div>
        
                            <div>
                                „Gebongt“ ist eine App, die jeder Kunde mit einem Smartphone nutzen kann. Durch eine geringfügige Änderung 
                                in der Kassensoftware kann die Kasse per NFC den Kassenbon digital auf das Gerät des Kunden übertragen 
                                und macht somit das Drucken des Bons überflüssig. <br><br>
                                
                                Wir sind ein vierköpfiges Entwicklerteam und haben in den letzten Jahren schon Erfahrung in der Konzeptionierung 
                                und Entwicklung von Apps an vielseitigen Projekten im IT-Sektor gesammelt. Unsere Vision ist langfristig die 
                                vollständige Abschaffung analoger Kassenbons. 
                            </div>
                            
                        </div>
                        <div class="content-sub-headline content-sub-headline-between">Wie funktioniert's? Von der Kasse zum Kunden</div>
                        <div class="content-text">
                            <div>
                                Der Schlüssel zu dem Konzept ist die Schnittstelle, die die Bons von dem Kassensystem auf das Smartphone der Kunden überträgt.<br><br>
        
                                Die Funktionsweise unseres Systems ist einfach. In der ersten Phase des Systems wird es zwei Übertragungsmöglichkeiten geben:<br><br>
        
                                Sofern die Kasse ein Display besitzt, wird ein QR-Code erzeugt, der mit der App gescannt werden kann. In diesem Fall 
                                wird überhaupt kein Papier verwendet.<br><br>
        
                                Ansonsten kann in der Übergangsphase der QR-Code auf dem Kassenbon ausgedruckt werden und später durch den Kunden 
                                gescannt werden. Weitere Möglichkeiten, wie z.B. die Übertragung durch die Funktechnik NFC, werden folgen.<br><br>
                            </div>
        
                            <div>
                                Verzögerungen an der Kasse werden vermieden, da der QR-Code gescannt werden kann, während der Kunde darauf wartet, 
                                dass die Waren an der Kasse erfasst werden. Nach dem Bezahlvorgang wird dann der vollständige Datensatz automatisch 
                                auf die Server übertragen und der Kassenbon an das per QR-Code verknüpfte Smartphone gesendet.<br><br>
        
                                Sollte mit Karte oder Smartphone gezahlt worden sein, muss das Zahlungsmittel nur einmalig registriert werden und 
                                es wird bei darauffolgenden Käufen der Beleg automatisch an den entsprechenden Account des Kunden gesendet.<br><br>
        
                                Nach dem Einkauf kann der Kunde den neuen Bon ganz einfach in der App herunterladen.
                            </div>
        
                        </div>
        
                        <div class="content-sub-headline content-sub-headline-between">Der Vorgang in drei Schritten</div>
                        
                        <div id="explain-container">
                            <div class="number-container number-container-first">
                                <div class="numbering-bg"><div class="numbering">1</div></div>
                                <div>
                                    <div class="explain-img-container explain-img-container-first">
                                        <img class="explain-img" src="${assetStep1}">
                                    </div>
                                    Die Kasse erhält bei Beginn eines neuen Scanvorgangs einen QR-Code.
                                </div>
                            </div>
        
                            <div class="number-container">
                                <div class="numbering-bg"><div class="numbering">2</div></div>
                                <div>
                                    <div class="explain-img-container">
                                        <img class="explain-img" src="${assetStep2}">
                                    </div>
                                    Der Kunde scannt den QR-Code an der Kasse, während seine Produkte gescannt werden.
                                </div>
                            </div>
        
        
        
                            <div class="number-container">
                                <div class="numbering-bg"><div class="numbering">3</div></div>
                                <div>
                                    <div class="explain-img-container">
                                        <img class="explain-img" src="${assetStep3}">
                                    </div>
                                    Der fertige Kassenbon wird verschlüsselt an die Cloud geschickt und von dort aus auf das Handy des Kunden übertragen.
                                </div>
                            </div>
                        </div>
        
                        <div class="content-headline"><div id="app-anchor"></div>Die App</div>
                        <div class="content-sub-headline">Ein Blick in das Design und den Aufbau</div>
        
                        <div class="screenshot-text">
                            <div class="screenshot-container">
                                <img class="screenshot" src="${assetScreen1}">
                            </div>
        
                            <div class="screenshot-text-content">
                                Die App gibt dem Kunden die Möglichkeit, die erhaltenen Kassenbons auf verschiedene Wege zu 
                                strukturieren und zu verwalten.<br><br>
                                
                                Beim Starten der App findet man zuerst eine Übersicht im Abschnitt „Bons“ über seine zuletzt gesammelten Kassenbons, 
                                die nach Datum sortiert sind. Hier werden das Ausstellungsdatum und die ausgegebene Summe angegeben.<br><br>
                                
                                Die Bons sind in der App in einem speziellen Format gespeichert und können bei Bedarf angesehen oder 
                                als PDF z.B. für den Druck exportiert werden.                    
                            </div>
                        </div>
        
                        <div class="screenshot-text">
                            <div class="screenshot-container screen-reverse">
                                <img class="screenshot" src="${assetScreen2}">
                            </div>
        
                            <div class="screenshot-text-content text-reverse">
                                Vielen Usern werden schon diese Funktionen der App vollständig im Alltagsleben genügen. Für Daten- und Statistikliebhaber
                                haben wir uns aber noch ein paar Extras einfallen lassen.<br><br>
                                
                                Ist ein Bon nun von besonderer Bedeutung, kann man ihn mit einem Label wie z.B. „absetzen“ oder „Firma“ versehen 
                                und in dem zweiten Abschnitt „Markiert“ wiederfinden. Hier  ist zunächst eine Übersicht 
                                der Läden, in denen man die verschiedenen Bons erhalten hat zu finden. Tippt man auf die Cards, werden die 
                                markierten Bons nach Labels gruppiert für den ausgewählten Laden angezeigt.                        
                            </div>
                        </div>
        
                        <div class="screenshot-text">
                            <div class="screenshot-container">
                                <img class="screenshot" src="${assetScreen3}">
                            </div>
        
                            <div class="screenshot-text-content">
                                Jetzt wird es noch ein bisschen verrückter. Die Kategorie „Analyse“ bietet dem Kunden die Möglichkeit, sein Kaufverhalten in Form von verschiedenen 
                                Diagrammen zu visualisieren.<br><br>
        
                                Neben der Strukturierung nach Käufen in den unterschiedlichen Ladenketten gibt es auch die Möglichkeit, nach 
                                Produktkategorien wie „Getränke“, „Schreibwaren“ oder „Lebensmittel“ zu gruppieren.<br><br>
        
                                Dies bietet dem Kunden eine strukturierte und nie dagewesene Übersicht über seine Einkäufe in den Ladenketten,
                                die eine Nutzung der App unterstützen.                     
                            </div>
                        </div>
        
                        <div class="content-headline"><div id="why-anchor"></div>Warum Gebongt?</div>
                        <div class="content-sub-headline">Zielgruppe und Veröffentlichung</div>
                        <div class="content-text">
        
                            <div>
                                Natürlich können nur Kunden mit einem Smartphone die App nutzen. In den letzten Jahren hat sich das Smartphone in allen 
                                Altersgruppen etabliert und es kann davon ausgegangen werden, das Kunden ohne Smartphonezugang eine 
                                unbedeutende Minderheit ausmachen. <br><br>
        
                                Um das Produkt bekannt zu machen und effektiv die Anzahl gedruckter Bons zu reduzieren, muss der Kunde auf die 
                                neue Einführung der App hingewiesen werden. 
                            </div> 
                            <div>
                                Durch simple Hinweise an der Kasse, durch Aufhängen von Plakaten und 
                                vor allem durch das Drucken der QR-Codes mit Hinweis auf die App auf die Kassenbons kann auf das neue System 
                                aufmerksam gemacht werden.<br><br>
        
                                Wie auf den vorigen Seiten gezeigt bietet die Nutzung der App dem Kunden viele praktische Vorteile gegenüber 
                                der ursprünglichen „Zettelwirtschaft“, weshalb mit Interesse von Seiten der Kunden gerechnet werden kann.
                            </div>
                            
                        </div>
        
                        <div class="content-sub-headline content-sub-headline-between">Langfristige Nutzung und Integration</div>
                        <div class="content-text">
        
                            <div>
                                Die Vorteile der Nutzung der App liegen auf der Hand: Sie reduzieren nachhaltig den Verbrauch an 
                                umweltbelastendem Thermo-Papier in Ihren Filialen und setzen sich somit für eine ökologisch 
                                nachhaltigere Ressourcennutzung ein. <br><br>
        
                                Zusätzlich stellen wir Ihnen den Code und den Datensatz für ein Banner zur Verfügung, welches Sie 
                                auf Ihrer Firmenwebsite einbinden können, um zum Beispiel die Länge und Masse an gespartem Papier 
                                zu veröffentlichen.
                            </div> 
                            <div>
                                Doch allein durch die Nutzung dieser Alternative zu den Papierbons können Sie nach außen hin Ihr 
                                Engagement rund um den Klimaschutz und Ihr Interesse an der Reduktion des ökologischen Fußabdrucks 
                                von uns allen zeigen. <br><br>
        
                                Durch die Reduktion der Kassenbonnutzung in größeren Dimensionen  wird im Weiteren auch weniger 
                                Aufwand im Bereich der Beschafffung, Logistik, Lagerhaltung und Disposition anfallen.
                            </div>
                            
                        </div>
        
                        <div class="content-sub-headline content-sub-headline-between">Ein deutschlandweiter Trend - Ohne Smartphone geht es nicht mehr: Smartphonenutzer in Deutschland in Mio.</div>
        
                        <img id="graphic" src="${assetUserGraphic}">
        
        
                        <div class="content-headline"><div id="privacy-anchor"></div>Datenschutz</div>
                        <div class="content-sub-headline">Verwaltung der vertraulichen Daten</div>
                        <div class="content-text">
        
                            <div>
                                Das Thema Datenschutz hat in den Medien vor allem im letzten Jahr für Schlagzeilen gesorgt und ist auch für 
                                uns oberste Priorität, da wir hier mit vertraulichen Daten der Kunden arbeiten.<br><br>
        
                                Wir verwenden für nahezu alle Transaktionen auf unserer Plattform Ende-zu-Ende Verschlüsselung, die das 
                                Lesen der Daten nur dem Empfänger des Kassenbons möglich macht. Die Daten werden mit den kryptographischen 
                                Verfahren AES und RSA verschlüsselt und sind somit vor etwaigen Angriffen bestmöglich geschützt.<br><br>
                            </div> 
        
                            <div>
                                Der Betrieb unserer Server-Systeme findet in einem ISO27001 zertifizierten Rechenzentrum statt. Diese 
                                Norm beschreibt die wichtigsten Grundsätze, um einen reibungslosen Betrieb gewährleisten zu können, 
                                explizit im Bereich der Informationssicherheit. Hierzu zählen die Einrichtung, die Optimierung, der 
                                Betrieb und die Dokumentation der Sicherheitsinfrastruktur bzw. Sicherheitseinrichtungen. 
                            </div>
                            
                        </div>
        
                        <div class="content-headline"><div id="contact-anchor"></div>Kontakt</div>
                        <!---<div class="content-sub-headline">Bei Interesse schreiben Sie uns gerne an.</div>
        
                        <app-content-box header="Kontaktformular" centerHeader></app-content-box>
                            <form id="contact-form" @submit="${this.sendContactForm}" onSubmit="return false">
                                <select name="type" id="contact-form-type" required="required">
                                    <option value="" disabled="disabled" selected="selected">Bereich auswählen</option>
                                    <option value="products">Produkte</option>
                                    <option value="product-schoolinfoapp">Proukt: SchulinfoApp</option>
                                    <option value="product-cedgedrive">Produkt: CedgeDrive</option>
                                    <option value="support">Support</option>
                                    <option value="legal">Rechtliches</option>
                                </select>
                                <input type="text" name="firstname" id="contact-form-firstname" placeholder="Vorname" required>
                                <input type="text" name="lastname" id="contact-form-lastname" placeholder="Nachname" required>
                                <input type="email" name="email" id="contact-form-email" placeholder="E-Mail Adresse" required>
                                <textarea name="message" id="contact-form-message" placeholder="Nachricht" required></textarea>
                                <div id="contact-form-checkboxes">
                                    <input type="checkbox" id="contact-form-checkbox" required>
                                    <label for="contact-form-checkbox">Ich akzeptiere die</label><span> </span><a class="link" href="https://cedgetec.com/privacy" target="_blank">Datenschutzerklärung</a>
                                </div>
                                <button id="contact-form-submit" type="submit">Absenden</button>
                            </form>
                        </app-content-box>-->
        
                        <div class="content-sub-headline content-sub-headline-between">Bei Interesse schreiben Sie uns gerne an.</div>
        
                        <app-content-box header="E-Mail und Telefon" centerHeader centerContent>
                            <div id="contact-info">
                                <a class="link contact-link" href="mailto:info@cedgetec.com">info@cedgetec.com</a>
                                <a class="link contact-link" href="tel:004940466665250">040 / 466665250</a>
                            </div>
                        </app-content-box>
        
        
                    </div>
        
                    <img id="blocks-left" src="${assetBgRight}">
                    <img id="blocks-right" src="${assetBgLeft}">
                </main>
        `
    }

    static styles = [GlobalCSS, css`
        
        #head-background {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100vh;
            background-image: linear-gradient(to bottom right, rgb(31, 123, 255), rgb(149, 189, 255));
            z-index: 2;
        }
        
        #head-headline {
            padding-left: 3rem;
            display: flex;
            flex-direction: column;
            color: white;
            max-width: 40%;
            z-index: 1;
        }
        
        #head-headline > div:nth-child(1) {
            font-size: 6rem;
            font-weight: 600;
            line-height: 6rem;
        }
        
        #head-headline > div:nth-child(2) {
            font-size: 5rem;
            font-weight: 300;
            line-height: 6rem;
        }
        
        #head-icon {
            margin-right: 3rem;
            width: 15%;
            z-index: 1;
        }
        
        @media screen and (max-width: 102rem) {
            #head-headline {
                max-width: 60%;
            }
            
            #head-headline > div:nth-child(1) {
                font-size: 5rem;
                line-height: 5rem;
            }
            
            #head-headline > div:nth-child(2) {
                font-size: 4rem;
                line-height: 5rem;
            }
        
            #head-icon {
                width: 12%;
            }
        }
        
        @media screen and (max-width: 95rem) {
            #head-icon {
                margin-right: 1.3rem;
            }
            #head-headline {
                padding-left: 1.3rem;
                max-width: 80%;
            }
        }
        
        @media screen and (max-width: 65rem) { 
            #head-background {
                flex-direction: column;
            }
        
            #head-headline {
                text-align: center;
                padding-left: 0;
            }
            
            #head-headline > div:nth-child(1) {
                font-size: 4rem;
                line-height: 5rem;
            }
            
            #head-headline > div:nth-child(2) {
                font-size: 3rem;
                line-height: 4rem;
            }
        
            #head-icon {
                margin-right: 0;
                margin-bottom: 2.5rem;
                width: 10rem;
            }
        }
        
        #head-img {
            position: absolute;
            width: 100%;
            z-index: 3;
            opacity: .9;
        }
        
        #head-fade {
            right: 0;
            position: absolute;
            height: 100%;
            z-index: 0;
            opacity: .6;
        }
        
        main {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
        }
        
        #navigation-marker {
            position: absolute;
            top: -5.5rem;
        }
        
        .content-block {
            margin-top: 3.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 52%;
            font-size: 1.1rem;
            color: rgb(92, 92, 92);
        }
        
        @media screen and (max-width: 90rem) {
            .content-block {
                width: 70%;
            }
        }
        
        @media screen and (max-width: 65rem) {
            .content-block {
                width: 80%;
            }
        }
        
        @media screen and (max-width: 50rem) {
            .content-block {
                width: 60%;
            }
        }

        @media screen and (max-width: 30rem) {
            .content-block {
                width:77%;
            }
        }
        
        .content-headline {
            margin-top: 3rem;
            font-size: 3.2rem;
            font-weight: 600;
            color: black;
            text-align: center;
            line-height: 3.8rem;
            position: relative;
            /* color: rgba(31, 123, 255, .8); */
        }
        
        .content-headline > div {
            position: absolute;
            top: -8rem;
        }

        @media screen and (max-width: 35rem) {
            .content-headline {
                margin-top: 1.7rem;
                font-size: 2.8rem;
            }
        }
        
        .content-sub-headline {
            margin-top: 1rem;
            margin-bottom: 3rem;
            font-size: 1.8rem;
            font-weight: 600;
            text-align: center;
            opacity: .7;
        }

        @media screen and (max-width: 35rem) {
            .content-sub-headline {
                font-size: 1.4rem;
            }
        }
        
        .content-sub-headline-between {
            margin-top: 3rem;
        }
        
        .content-text {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.7rem;
        }
        
        
        @media screen and (max-width: 50rem) {
            .content-text {
                grid-template-columns: 1fr;
                grid-template-rows: auto auto;
                row-gap: 1rem;
            }
        }
        
        .explain-img {
            height: 8rem;
            max-width: 100%;
            object-position: center bottom;
            background-origin: border-box;
            box-sizing: border-box;
            opacity: .6;
        }
        
        .explain-img-container {
            margin-top: 3.5rem;
            margin-bottom: 2.5rem;
            display: flex;
            justify-content: center;
            width: 100%;
        }
        
        @media screen and (max-width: 50rem) {
            .explain-img-container {
                margin-top: 2rem;
                margin-bottom: 1.5rem;
            }
        }
        
        .explain-img-container-first {
            margin-top: 1.4rem;
        }
        
        .numbering {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 3rem;
            font-weight: 900;
            line-height: 3.5rem;
            color: white;
        }
        
        @media screen and (max-width: 50rem) {
            .numbernumbering {
                font-size: 2.3rem;
            }
        }
        
        .numbering-bg {
            margin-top: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            width: 7rem;
            height: 7rem;
            background-color: rgb(71,150,255);
            border-radius: 50%;
        }
        
        @media screen and (max-width: 50rem) {
            .numbering-bg {
                width: 6.3rem;
                height: 6.3rem;
                margin-top: 0;
            }
        }
        
        .number-container-first {
        
        }
        
        @media screen and (max-width: 50rem) {
            .number-container-first {
                margin-top: 1rem !important;
            }
        }
        
        .number-container {
            display: grid;
            grid-template-columns: 28% 72%;
            column-gap: 2rem;
        }
        
        @media screen and (max-width: 70rem) {
            .number-container {
                column-gap: 1rem;
            }
        }
        
        @media screen and (max-width: 50rem) {
            .number-container {
                margin-top: 4rem;
                justify-items: center;
                grid-template-columns: inherit;
                grid-template-rows: auto auto;
                column-gap: 0;
                text-align: center;
            }
        }
        
        .number-container > div:nth-child(1) {
            grid-column: 1;
        }
        
        .number-container > div:nth-child(2) {
            grid-column: 2;
        }
        
        @media screen and (max-width: 50rem) {
            .number-container > div:nth-child(1) {
                grid-row: 1;
            }
            
            .number-container > div:nth-child(2) {
                grid-column: inherit;
                grid-row: 2;
            }
        }
        
        #explain-container {
            margin-bottom: 2rem;
            width: 70%;
        }
        
        @media screen and (max-width: 70rem) {
            #explain-container {
                width: 90%;
            }
        }
        
        .screenshot-container {
            display: flex;
            justify-content: flex-end;
            grid-column: 1;
        }
        
        @media screen and (max-width: 50rem) {
            .screenshot-container {
                margin-bottom: 2.5rem;
                grid-column: inherit;
                justify-content: center;
                grid-row: 1;
            }
        }
        
        .screen-reverse {
            grid-column: 2;
            grid-row: 1;
        }
        
        @media screen and (max-width: 50rem) {
            .screen-reverse {
                grid-column: inherit;
                grid-row: 1;
            }
        }
        
        .screenshot {
            max-width: 20rem;
            object-position: center bottom;
            object-fit: cover;
            background-origin: border-box;
            box-sizing: border-box;
        }

        @media screen and (max-width: 50rem) {
            .screenshot {
                width: 16rem;
            }
        }
        
        .screenshot-text {
            margin-top: 1.4rem;
            margin-bottom: 4rem;
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 3rem;
            width: 75%;
        }

        @media screen and (max-width: 35rem) {
            .screenshot-text {
                margin-bottom: 2rem;
            }
        }
        
        .screenshot-text-content {
            grid-column: 2;
        }
        
        @media screen and (max-width: 50rem) {
            .screenshot-text-content {
                grid-column: inherit;
                grid-row: 2;
            }
        }
        
        .text-reverse {
            grid-column: 1;
            grid-row: 1;
        }
        
        @media screen and (max-width: 50rem) {
            .text-reverse {
                grid-row: 2;
            }
        }
        
        @media screen and (max-width: 110rem) {
            .screenshot-text {
                grid-template-columns: auto;
                grid-template-rows: auto auto;
                width: 88%;
                column-gap: 1.5rem;
            }
        }
        
        @media screen and (max-width: 90rem) {
            .screenshot-text {
                width: 80%;
            }
        }
        
        @media screen and (max-width: 70rem) {
            .screenshot-text {
                width: 95%;
            }
        }
        
        @media screen and (max-width: 50rem) {
            .screenshot-text {
                grid-template-columns: 1fr;
                grid-template-rows: auto auto;
                column-gap: 0;
                row-gap: 1.5rem;
                width: 100%;
            }
        }
        
        #graphic {
            margin-bottom: 3rem;
            width: 80%;
        }

        @media screen and (max-width: 35rem) {
            #graphic {
                margin-bottom: 2rem;
                width: 100%;
            }
        }
        
        #blocks-left {
            bottom: -6rem;
            left: 0;
            position: absolute;
            width: 22rem;
            z-index: 3;
        }
        
        #blocks-right {
            top: -5rem;
            right: 0;
            position: absolute;
            width: 25rem;
        }
        
        @media screen and (max-width: 90rem) {
            #blocks-right {
                display: none;
            }
        
            #blocks-left {
                display: none;
            }
        }
        
        
        /* KONTAKT SEITE */

        #contact-form {
            margin-top: 1rem;
            width: 25rem;
            max-width: 100%;
        }
        
        @media screen and (max-width: 35rem) {
            #contact-form {
                width: 100%;
            }
        }
        
        #contact-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 1.1rem;
        }
        
        .contact-link {
            margin-top: 1rem;
        }
        
        input[type="text"], input[type="email"], input[type="submit"], select, textarea, button {
            box-sizing: border-box;
            margin: 0.5rem;
            padding: 0.6rem;
            min-width: 100%;
            max-width: 100%;
            border: 0.18rem solid rgb(71,150,255);
            background-color: #ffffff;
            outline: none;
        }
        
        select {
            cursor: pointer;
        }
        
        select option {
            outline: none;
        }
        
        textarea {
            min-height: 9rem;
            max-height: 16rem;
        }
        
        #contact-form-checkboxes {
            user-select: none;
            margin: 0.5rem;
            text-align: left;
        }
        
        @media screen and (max-width: 35rem) {
            #contact-form-checkboxes {
                text-align: center;
            }
        }
        
        input[type="checkbox"] {
            margin-right: 0.5rem;
        }
        
        #contact-form-message {
            font: 400 13.3333px Arial;
        }
        
        button {
            margin-top: 1rem;
            transition: background-color 0.3s, color 0.3s;
            cursor: pointer;
        }
        
        button:hover {
            background-color: rgb(71,150,255);
            color: #ffffff;

    `]
}